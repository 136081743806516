import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { alias, or, readOnly } from "@ember/object/computed";
import { ajax } from "discourse/lib/ajax";
import DiscourseURL, { userPath } from "discourse/lib/url";
import { modKeysPressed } from "discourse/lib/utilities";
import { getWebauthnCredential } from "discourse/lib/webauthn";
import PasswordValidation from "discourse/mixins/password-validation";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend(PasswordValidation, (_dec = discourseComputed("model.security_key_required"), _dec2 = discourseComputed(), _dec3 = discourseComputed("redirectTo"), (_obj = {
  isDeveloper: alias("model.is_developer"),
  admin: alias("model.admin"),
  secondFactorRequired: alias("model.second_factor_required"),
  securityKeyRequired: alias("model.security_key_required"),
  backupEnabled: alias("model.backup_enabled"),
  securityKeyOrSecondFactorRequired: or("model.second_factor_required", "model.security_key_required"),
  otherMethodAllowed: readOnly("model.multiple_second_factor_methods"),
  secondFactorMethod(security_key_required) {
    return security_key_required ? SECOND_FACTOR_METHODS.SECURITY_KEY : SECOND_FACTOR_METHODS.TOTP;
  },
  passwordRequired: true,
  errorMessage: null,
  successMessage: null,
  requiresApproval: false,
  redirected: false,
  maskPassword: true,
  init() {
    this._super(...arguments);
    this.set("selectedSecondFactorMethod", this.secondFactorMethod);
  },
  continueButtonText() {
    return I18n.t("password_reset.continue", {
      site_name: this.siteSettings.title
    });
  },
  redirectHref(redirectTo) {
    return getURL(redirectTo || "/");
  },
  lockImageUrl: getURL("/images/lock.svg"),
  done(event) {
    if (event && modKeysPressed(event).length > 0) {
      return false;
    }
    event?.preventDefault();
    this.set("redirected", true);
    DiscourseURL.redirectTo(this.redirectTo || "/");
  },
  togglePasswordMask() {
    this.toggleProperty("maskPassword");
  },
  actions: {
    submit() {
      ajax({
        url: userPath(`password-reset/${this.get("model.token")}.json`),
        type: "PUT",
        data: {
          password: this.accountPassword,
          second_factor_token: this.securityKeyCredential || this.secondFactorToken,
          second_factor_method: this.selectedSecondFactorMethod,
          timezone: moment.tz.guess()
        }
      }).then(result => {
        if (result.success) {
          this.set("successMessage", result.message);
          this.set("redirectTo", result.redirect_to);
          if (result.requires_approval) {
            this.set("requiresApproval", true);
          } else {
            this.set("redirected", true);
            DiscourseURL.redirectTo(result.redirect_to || "/");
          }
        } else {
          if (result.errors && !result.errors.password) {
            this.setProperties({
              secondFactorRequired: this.secondFactorRequired,
              securityKeyRequired: this.securityKeyRequired,
              password: null,
              errorMessage: result.message
            });
          } else if (this.secondFactorRequired || this.securityKeyRequired) {
            this.setProperties({
              secondFactorRequired: false,
              securityKeyRequired: false,
              errorMessage: null
            });
          } else if (result.errors && result.errors.password && result.errors.password.length > 0) {
            this.rejectedPasswords.pushObject(this.accountPassword);
            this.rejectedPasswordsMessages.set(this.accountPassword, (result.friendly_messages || []).join("\n"));
          }
          if (result.message) {
            this.set("errorMessage", result.message);
          }
        }
      }).catch(e => {
        if (e.jqXHR && e.jqXHR.status === 429) {
          this.set("errorMessage", I18n.t("user.second_factor.rate_limit"));
        } else {
          throw new Error(e);
        }
      });
    },
    authenticateSecurityKey() {
      getWebauthnCredential(this.model.challenge, this.model.allowed_credential_ids, credentialData => {
        this.set("securityKeyCredential", credentialData);
        this.send("submit");
      }, errorMessage => {
        this.setProperties({
          securityKeyRequired: true,
          password: null,
          errorMessage
        });
      });
    }
  }
}, (_applyDecoratedDescriptor(_obj, "secondFactorMethod", [_dec], Object.getOwnPropertyDescriptor(_obj, "secondFactorMethod"), _obj), _applyDecoratedDescriptor(_obj, "continueButtonText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "continueButtonText"), _obj), _applyDecoratedDescriptor(_obj, "redirectHref", [_dec3], Object.getOwnPropertyDescriptor(_obj, "redirectHref"), _obj), _applyDecoratedDescriptor(_obj, "done", [action], Object.getOwnPropertyDescriptor(_obj, "done"), _obj), _applyDecoratedDescriptor(_obj, "togglePasswordMask", [action], Object.getOwnPropertyDescriptor(_obj, "togglePasswordMask"), _obj)), _obj)));